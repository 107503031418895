<template>
	<!-- 预出库单 -->
	<div class="preDeliveryNote">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -9999999px;">
			<div class="export-template" v-for="(item, index) in selectionList" :key="index">
				<div :id="'template-title' + index">
					<div style="line-height: 50px;font-size: 22px;" align=center>
						<strong>
							<font>{{ supplier_info.supplier_name }}配送单</font>
						</strong>
					</div>
					<div class="title-box">
						<div class="box-l">
							<div class="l-item bold">提货方式：{{ item.delivery_type == 1 ?
                                '配送【' + (item.deliveryline ? item.deliveryline.title : '') + '】,运费' + item.delivery_fee +
                                '元,'
                                +
                                (item.delivery_fee_status == 0 ? '未付' : '已付')
                                : '自提' }}</div>
							<div class="l-item">
								<span>单号：{{ item.sn }}</span>
								<span>仓库：{{ item.warehouse ? item.warehouse.name : '暂无' }}</span>
							</div>
							<div class="l-item">
								<span>配送日期：{{ item.date }}</span>
								<span>汇总日期：{{ item.exec_date }}</span>
							</div>
							<div class="l-item">收货人：{{ item.contacts }}-{{ item.phone }}-{{ item.address }}</div>

						</div>
						<div class="box-r">
							{{ index + 1 }}
							<!-- <img
                                src="https://jijingsuyuan.oss-cn-chengdu.aliyuncs.com/static_easyadmin/20240626/Pnr1719394807rIKvVA.png" /> -->
						</div>
					</div>
				</div>
				<div :id="'template-table' + index">
					<table border=1 width="100%" style="border-collapse:collapse" bordercolor="#333333">
						<thead>
							<tr>
								<td width="15%" style="padding:8px">
									<div align=center><b>序号</b></div>
								</td>
								<td width="55%" style="padding:8px">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="15%" style="padding:8px">
									<div align=center><b>订货数</b></div>
								</td>
								<td width="15%" style="padding:8px">
									<div align=center><b>确认数</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(itemChil, indexChil) in item.goodsList" :key="indexChil">
								<td align=center style="padding:8px">{{ indexChil + 1 }}</td>
								<td align=center style="padding:8px">{{ itemChil.goods_name }}</td>
								<td align=center style="padding:8px">{{ itemChil.count }}</td>
								<td align=center style="padding:8px">{{ itemChil.ck_count }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配送日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择"
							@focus="routeType = 1, getRouteList()">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">出库单号:</div>
					<div class="item-input">
						<el-input v-model="searchForm.sn" placeholder="请输入预出库单号" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人姓名" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">地址:</div>
					<div class="item-input">
						<el-input v-model="searchForm.address" placeholder="请输入地址" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择状态" @change="searchForm.status = $event">
							<el-option v-for="item in statusType" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">提货方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
							<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">售后:</div>
					<div class="item-input">
						<el-select v-model="searchForm.has_service" placeholder="请选择是否包含售后"
							@change="searchForm.has_service = $event">
							<el-option v-for="item in hasServiceType" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="primary" @click="orderDialogVisible = true">生成配送单</el-button>
				<el-button type="success" @click="handlePrint" :disabled="exportDisabled">批量打印</el-button>
				<el-button type="success" @click="handleExport">导出</el-button>
				<el-button type="warning" @click="handleOperation(1)" :disabled="operationDisabled">批量确认</el-button>
				<el-button type="danger" @click="handleOperation(9)" :disabled="operationDisabled">批量取消</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" @selection-change="selectionChange">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.goodsList"
							:header-cell-style="{fontSize: '14px', backgroundColor: '#f9f9f9',color:'#333'}"
							:cell-style="{ backgroundColor: '#f9f9f9'}" style="width: 100%;border:1px solid #e6a23c">
							<el-table-column prop="goods_name" label="商品名称" align="center">
								<template slot-scope="scope">
									<span
										v-if="searchForm.goods_name && scope.row.goods_name.indexOf(searchForm.goods_name)>=0"
										style="color:#5500ff;font-weight: 600;">{{ scope.row.goods_name }}</span>
									<span v-else>{{ scope.row.goods_name }}</span>
								</template>
							</el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column label="状态" align="center">
								<template slot-scope="scope">
									<span v-if="scope.row.status == 0">待确认</span>
									<span v-if="scope.row.status == 1">已确认</span>
									<span v-if="scope.row.status == 2">已延期</span>
									<span v-if="scope.row.status == 9">已取消</span>
								</template>
							</el-table-column>
							<el-table-column prop="price" label="订单价" align="center"></el-table-column>
							<el-table-column prop="count" label="数量" align="center"></el-table-column>
							<el-table-column prop="amount" label="订单金额" align="center"></el-table-column>
							<el-table-column prop="ck_count" label="配送数量" align="center"></el-table-column>
							<el-table-column prop="ck_amount" label="配送金额" align="center"></el-table-column>
							<el-table-column prop="sys_remark" label="说明" align="center"></el-table-column>
							<el-table-column label="操作" align="center" width="260">
								<template slot-scope="scope">
									<el-button type="primary" size="mini" v-if="scope.row.status == 0"
										@click="dialogVisibleType = 2, dialogVisible = true, listItem = scope.row">延期</el-button>
									<el-button type="danger" size="mini" v-if="scope.row.status == 0"
										@click="handleGoodsStatus(scope.row, 9)">取消</el-button>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">待确认</span>
						<span v-if="scope.row.status == 1">已确认</span>
						<span v-if="scope.row.status == 2">已延期</span>
						<span v-if="scope.row.status == 9">已取消</span>
					</template>
				</el-table-column>
				<el-table-column label="下单团长" align="center">
					<template slot-scope="scope">
						<p>{{ scope.row.leader.username }}</p>
						<p> {{ scope.row.leader.phone }}</p>
					</template>
				</el-table-column>
				<el-table-column label="配送单号" prop="sn" align="center"></el-table-column>
				<el-table-column label="配送日期" prop="date" align="center"></el-table-column>
				<el-table-column label="订单笔数" prop="purorder_count" align="center"></el-table-column>
				<el-table-column label="订单金额" prop="purorder_amount" align="center"></el-table-column>
				<el-table-column label="提货方式" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.delivery_type == 2">自提</span>
						<span v-if="scope.row.delivery_type == 1">配送</span>
					</template>
				</el-table-column>
				<el-table-column label="运费" align="center">
					<template slot-scope="scope">
						<span>运费{{ scope.row.delivery_fee }}元,{{ scope.row.delivery_fee_status == 1 ? '已付' : '未付' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="类型" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.type == 1">正常</span>
						<span v-if="scope.row.type == 2" style="color:red">延期</span>
					</template>
				</el-table-column>
				<el-table-column label="配送路线" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.deliveryline">
							<span>{{ scope.row.deliveryline.title }}</span>
						</div>
						<div v-else>未设置</div>
					</template>
				</el-table-column>
				<el-table-column label="收货人" align="center" width="120">
					<template slot-scope="scope">
						<p>{{ scope.row.contacts }}</p>
						<p>{{ scope.row.phone }}</p>
						<p>{{ scope.row.address }}</p>
					</template>
				</el-table-column>
				<el-table-column label="汇总时间" prop="exec_date" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="400">
					<template slot-scope="scope">
						<el-button type="success" size="mini" v-if="scope.row.status == 1"
							@click="handlePrint(scope.row)">打印</el-button>
						<el-button type="primary" size="mini" v-if="scope.row.status == 0"
							@click="dialogVisibleType = 1, dialogVisible = true, listItem = scope.row">整单延期</el-button>
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click="handleStatus(scope.row, 1)">确认</el-button>
						<el-button type="danger" size="mini" v-if="scope.row.status == 0"
							@click="handleStatus(scope.row, 9)">取消</el-button>
						<el-button type="warning" size="mini" v-if="scope.row.status == 0"
							@click="handleDeliveryDialog(scope.row)">配送方式</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 生成配送单 -->
		<el-dialog title="生成配送单" :visible.sync="orderDialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="日期">
					<el-date-picker v-model="orderDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions" style="width:100%">
					</el-date-picker>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="orderDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="orderConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 延期 -->
		<el-dialog :title="dialogVisibleType == 1 ? '整单延期' : '商品延期'" :visible.sync="dialogVisible"
			:close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="form" :model="form" label-width="100px">
				<el-form-item label="延期数量" v-if="dialogVisibleType == 2">
					<el-input v-model="form.count" placeholder="请输入延期数量"></el-input>
				</el-form-item>
				<el-form-item label="预出库日期">
					<el-date-picker v-model="form.date" type="date" placeholder="选择预出库日期" value-format="yyyy-MM-dd"
						:picker-options="pickerOptions" style="width:100%">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="提货方式">
					<el-select v-model="form.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
						<el-option v-for="item in deliveryType" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属路线" v-if="form.delivery_type == 1">
					<el-select v-model="form.delivery_line_id" placeholder="请选择所属路线" style="width: 100%;"
						@focus="routeType = '', getRouteList()">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="运费费用" v-if="form.delivery_type == 1">
					<el-input v-model="form.delivery_fee" placeholder="请输入运费费用"></el-input>
				</el-form-item>
				<el-form-item label="运费是否支付" v-if="form.delivery_type == 1">
					<el-select v-model="form.delivery_fee_status" placeholder="请选择运费是否支付" style="width: 100%;"
						@change="form.delivery_fee_status = $event">
						<el-option v-for="item in deliveryFeeStatus" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 更改配送方式 -->
		<el-dialog title="更改配送方式" :visible.sync="deliveryDialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="deliveryForm" label-width="120px">
				<el-form-item label="配送费支付状态">
					<el-select v-model="deliveryForm.delivery_fee_status" placeholder="请选择配送费支付状态" style="width: 100%;">
						<el-option v-for="item in deliveryStatusList" :key="item.id" :label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属路线">
					<el-select v-model="deliveryForm.delivery_line_id" placeholder="请选择所属路线" style="width: 100%;"
						@focus="routeType = '', getRouteList()">
						<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="提货方式">
					<el-select v-model="deliveryForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;">
						<el-option v-for="item in deliveryList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="配送费">
					<el-input v-model="deliveryForm.delivery_fee" placeholder="请输入配送费"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="deliveryDialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmDeliveryFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					delivery_line_id: ''
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				orderDate: '',
				orderDialogVisible: false,
				// 供应商信息
				supplier_info: {},
				// 状态
				statusType: [{
						id: 0,
						name: '待确认'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 2,
						name: '已延期'
					},
					{
						id: 9,
						name: '取消'
					}
				],
				// 含不含售后
				hasServiceType: [{
						id: 0,
						name: '不含售后'
					},
					{
						id: 1,
						name: '有售后'
					}
				],
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				// 选择表格的数据
				selectionList: [],
				// 提货方式
				deliveryType: [{
						id: 1,
						name: '配送'
					},
					{
						id: 2,
						name: '自提'
					}
				],
				// 配送费支付状态
				deliveryStatusList: [{
						id: 0,
						name: '未支付'
					},
					{
						id: 1,
						name: '已支付'
					}
				],
				// 运费是否支付
				deliveryFeeStatus: [{
						id: 0,
						name: '未付'
					},
					{
						id: 1,
						name: '已付'
					}
				],
				// 编辑
				dialogVisibleType: 1,
				dialogVisible: false,
				form: {
					date: ''
				},
				// 配送方式
				deliveryForm: {},
				deliveryDialogVisible: false,
				routeList: [],
				// 是否配送
				deliveryList: [{
						id: 1,
						name: '配送'
					},
					{
						id: 2,
						name: '自提'
					}
				],
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				exportDisabled: false, //打印禁用
				operationDisabled: false, //批量确认、取消禁用
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 列表
			this.getList()
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
		},
		methods: {
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.$http.post(`erp/v1/preck/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选择表格数据
			selectionChange(e) {
				this.selectionList = e
				// // 打印
				// const exportArr = this.selectionList.every(i => {
				//     return i.status === 1
				// })
				// if (exportArr) {
				//     this.exportDisabled = false
				// } else {
				//     this.exportDisabled = true
				// }
				// 批量确认、取消
				const operationArr = this.selectionList.every(i => {
					return i.status === 0
				})
				if (operationArr) {
					this.operationDisabled = false
				} else {
					this.operationDisabled = true
				}
			},
			// 生成预出库单号
			orderConfirmFn() {
				if (!this.orderDate) {
					this.$message({
						type: 'error',
						message: '请选择日期'
					});
					return
				}
				this.loading = true
				this.$http.post(`erp/v1/preck/generate`, {
					date: this.orderDate
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dateVal = [this.orderDate, this.orderDate]
						this.searchForm.begin = this.orderDate
						this.searchForm.end = this.orderDate
						this.getList()
						this.orderDialogVisible = false
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint(item) {
				if (item.sn) {
					this.selectionList = []
					this.selectionList.push(item)
				} else {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择导出数据');
						return
					}
				}
				setTimeout(() => {
					var LODOP = getLodop();
					var totalPrint = 0
					for (var i = 0; i < this.selectionList.length; i++) {
						totalPrint++
						var titleStyle =
							"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r{font-size:20px} .box-r img {width: 100px;height: 100px;}</style>"
						LODOP.ADD_PRINT_HTM(2, "5%", "90%", 170, titleStyle + document.getElementById(
							"template-title" + i).innerHTML);
						var strStyle =
							"<style> table,td,th {border-width: 1px;border-style: solid;border-collapse: collapse;}</style>"
						LODOP.ADD_PRINT_TABLE(170, "5%", "90%", 800, strStyle + document.getElementById(
							"template-table" + i).innerHTML);
						LODOP.NewPageA();
						if (totalPrint == this.selectionList.length) { // 如果已经是最后一份，则终止此循环
							LODOP.PREVIEW()
						}
					}
					this.getList()
					this.selectionList = []
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`erp/v1/preck/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 批量确认、取消
			handleOperation(type) {
				if (this.selectionList.length <= 0) {
					this.$message({
						type: 'error',
						message: '请选择数据'
					});
					return
				}
				let idArr = []
				this.selectionList.forEach(item => {
					idArr.push(item.id)
				});
				this.$confirm('批量操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/status`, {
						id: idArr.join(','),
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			// 确认/取消
			handleStatus(item, type) {
				this.$confirm('操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/status`, {
						id: item.id,
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 确认/取消
			handleGoodsStatus(item, type) {
				this.$confirm('操作该数据吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/preck/goods_status`, {
						id: item.id,
						status: type
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						if (this.routeType == 1) {
							this.routeList.unshift({
								delivery_line_id: 0,
								title: '未设置'
							})
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 配送方式弹窗
			handleDeliveryDialog(item) {
				this.deliveryDialogVisible = true
				this.deliveryForm = {
					id: item.id,
					delivery_line_id: '',
					delivery_fee_status: item.delivery_fee_status,
					delivery_type: item.delivery_type,
					delivery_fee: item.delivery_fee,
				}
				console.log(this.deliveryForm, ' this.deliveryForm');
			},
			// 修改配送方式
			confirmDeliveryFn() {
				if (!this.deliveryForm.delivery_line_id) {
					this.$message.error('请选择所属路线');
					return
				}
				this.$http.post(`erp/v1/preck/set`, {
					...this.deliveryForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.deliveryDialogVisible = false
						this.$message.success(msg);
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 延期确定
			confirmFn() {
				if (this.form.delivery_type == 2) {
					this.form.delivery_line_id == ''
					this.form.delivery_fee == ''
					this.form.delivery_fee_status == ''
				}
				let url = this.dialogVisibleType == 1 ? '/erp/v1/preck/order_delay' : '/erp/v1/preck/goods_delay'
				if (this.dialogVisibleType == 1) {
					var info = {
						id: this.listItem.id,
						...this.form
					}
				} else {
					var info = {
						pre_ckd_mx_id: this.listItem.id,
						...this.form
					}
				}
				this.$http.post(`${url}`, info).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.getList()
						this.$message.success(msg);
						this.dialogVisible = false
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>